import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { NbTokenLocalStorage } from '@nebular/auth';
import { ApiServiceService } from './api-service.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: NbTokenLocalStorage,
    private apiservice: ApiServiceService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Check if the request has the custom header to skip the interceptor
    if (req.headers.has('X-Skip-Interceptor')) {
      const clonedRequest = req.clone({
        headers: req.headers.delete('X-Skip-Interceptor'), // Remove the custom header before sending the request
      });
      return next.handle(clonedRequest);
    }

    // Attach token to the request
    const token = this.authService.get().getValue();
    let authReq = req;

    if (token) {
      authReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    // Handle errors
    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // Handle 401 Unauthorized errors
          console.error('401 Unauthorized error');
          this.apiservice.toastrError('Session Expired', 'Error');
          this.apiservice.logout();

          this.router.navigate(['/auth/logout']); // Redirect to login page
        }
        return throwError(error); // Pass the error to the caller
      })
    );
  }
}