import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';
import { NbToastrService } from '@nebular/theme';
// import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import { Router } from '@angular/router';
// import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { ApiServiceService } from '../../../api-service.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  loading: boolean = false;
  loading1: boolean = false;
  activeusername = JSON.parse(localStorage.getItem('accessUser')) !== null ? JSON.parse(localStorage.getItem('accessUser'))?.name : null;
  logo: string = environment.logo;
  product: string = environment.product;
  sync_status: string = localStorage.getItem('sync_status');
  updated_time: string = localStorage.getItem('updated_time');
  private sipoUserSubscription: Subscription;
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'corporate';
  masterLogin:boolean=false;
 // userMenu = [{ title: 'Profile' },{ title: 'Settings' }, { title: 'Log out' }];
  userMenu = [
    { title: 'Profile', icon: 'person-outline' },
    { title: 'Store Settings', icon: 'settings-outline' },
    { title: 'Log out', icon: 'log-out-outline' },
  ];
  internetStatus: boolean;
  private internetStatusSubscription: Subscription;

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    // private userService: UserData,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private router: Router,
    // private authService: NbAuthService,
    private apiservice: ApiServiceService,
    private toastrService: NbToastrService) {
    this.apiservice.loginUser.subscribe((dat) => {
      this.activeusername = dat !== null ? dat?.name : null;
      //console.log(dat);
    }
    );
   
    if( localStorage.getItem('sipo_switch_login'))
    {
      this.masterLogin=true;
      this.userMenu.push({title:'Switch To Master',icon:'swap-outline'});
    }
    
  }
  initialLoadCompleted: boolean = false;
  ngOnInit() {

    //console.log(this.logo);

    this.getSyncStatus('initial');

    this.currentTheme = this.themeService.currentTheme;

    this.apiservice.getUserInfo().subscribe((dat) => {
      this.user = dat['user'];
      //console.log("before value :",this.user);
      localStorage.setItem('sipo_user', JSON.stringify(dat['user']));
      
    },
    (err) => {
        // Handle error scenarios
        console.log(err);
      
    }
    );

    this.apiservice.sipoUser.subscribe((dat) => {
      this.user = dat? dat : null;
      //console.log("after value :",this.user);
    }
    );

    this.internetStatusSubscription = this.apiservice.internetStatus$.subscribe((status) => {
      this.internetStatus = status;
      if(this.internetStatus == true && this.initialLoadCompleted == true){
        this.apiservice.toastrSuccess('Network Status', 'Internet Connected');
      }
      else if(this.internetStatus == false && this.initialLoadCompleted == true){
        this.apiservice.toastrError('Network Status', 'Internet Disconnected');
      }
    });
    

    this.apiservice.checkStaffLogin().subscribe((dat) => {
      //console.log(dat['stafflogin']['is_staff_login']);
      if (dat['stafflogin']['is_staff_login'] == 1) {
        localStorage.setItem('is_staff_login', 'true');

      }
      else {
        localStorage.setItem('is_staff_login', 'false');
      }
    });

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);

    this.menuService.onItemClick().subscribe((event) => {
      if (event.item.title === 'Log out') {
        localStorage.removeItem('accessUser');
        localStorage.removeItem('sipo_user');
        if(localStorage.getItem('sipo_master_token'))
        {
          localStorage.removeItem('sipo_master_token');
          localStorage.removeItem('sipo_switch_login');
        }
        this.apiservice.updateLoggedUser();
        this.apiservice.updateSipoUser();
        this.router.navigateByUrl('/auth/logout');

      } else if (event.item.title === 'Profile') {
        this.router.navigateByUrl('/pages/profile');
      
      } else if (event.item.title === 'Switch To Master') {
        this.backToMaster();
      }    
       else if (event.item.title === 'Store Settings') {
        this.router.navigateByUrl('/pages/user-basics');
      }
    

    });

    this.getReportSettings();
    this.initialLoadCompleted = true;
  }


  backToMaster(){
    let token= localStorage.getItem('sipo_master_token');
    if(token){
      this.router.navigateByUrl('auth/tck-login?token='+token);
      localStorage.removeItem('sipo_master_token');
      localStorage.removeItem('sipo_switch_login');
    }
  }


  logout(){
        localStorage.removeItem('accessUser');
        localStorage.removeItem('sipo_user');
        localStorage.removeItem('is_staff_login');
        localStorage.removeItem('sync_status');
        localStorage.removeItem('updated_time');
        localStorage.removeItem('report_from');
        localStorage.removeItem('report_to');
        localStorage.removeItem('reportVersion');
        if(localStorage.getItem('sipo_master_token'))
        {
          localStorage.removeItem('sipo_master_token');
          localStorage.removeItem('sipo_switch_login');
        }
        this.apiservice.updateLoggedUser();
        this.apiservice.updateSipoUser();
      this.router.navigateByUrl('/auth/logout');
  }


  getReportSettings(){
    this.apiservice.getReportSettings().subscribe((res:any)=>{
      //console.log(res);
      if(res['data'])
      {
        localStorage.setItem('report_from',res['data']['report_start']);
        localStorage.setItem('report_to',res['data']['report_end']);
        localStorage.setItem('reportVersion',res['data']['version']);
      }
      else
      {
        let start = "03:00";
        let end = "03:00";
        localStorage.setItem('report_from',start);
        localStorage.setItem('report_to',end);
      }
    },
    (err)=>{
      console.log(err);
    }
    );
  }

  forcePush() {
    this.toastrService.warning("Started", 'Syncing new changes to Pos...');
    this.loading = true;
    this.loading1 = true;
    this.apiservice.forcePush().subscribe((dat) => {
      this.loading = false;
      //console.log('check Point', dat);
      let status = dat['Status'];
      this.getSyncStatus();
    },
      (err) => {
        this.toastrService.danger("Failed", 'Sync to Pos');
        this.loading = false;
      }
    );


    this.apiservice.productDataSync().subscribe((dat) => {
      this.loading1 = false;
      // this.toastrService.success("Completed", 'Menu data sync');
    }, (err) => {
      console.log(err);
      this.toastrService.danger("Failed", 'Failed to sync menu data');
      this.loading1 = false;
    }
    );

  }

  getSyncStatus(type = null) {
    this.apiservice.getSyncStatus().subscribe((dat) => {
      //console.log(dat);
      let status = dat['Status'];
      localStorage.setItem('sync_status',status);
      localStorage.setItem('updated_time',dat['updatedat']);
      this.sync_status = status;
      this.updated_time = dat['updatedat'];
      if(type != 'initial'){
      if (status === 'PROGRESS') {
        this.loading = true;
        setTimeout(() => {
          this.getSyncStatus(); // Call the function again after 1 minute
        }, 60000); // 1 minute = 60,000 milliseconds
      }
      else if(status === 'COMPLETED' || status === 'FCOMPLETED'){
        this.toastrService.success("Completed", 'Sync to Pos');
        this.loading = false;
      }
      else if( status === 'FAILED' ){
        this.toastrService.danger("Failed,Retry agian !", 'Sync to Pos');
        this.loading = false;
      }
    }
    },
      (err) => {
        console.log(err);
      }
    );
  }


  gotoStart() {
    this.router.navigateByUrl('/pages/start');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.sipoUserSubscription) {
      this.sipoUserSubscription.unsubscribe();
    }
    if (this.internetStatusSubscription) {
      this.internetStatusSubscription.unsubscribe();
    }
    
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
}
