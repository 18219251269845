import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
@Component({
  selector: 'ngx-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit {

  constructor(private ref:NbDialogRef<FileViewerComponent>,private sanitizer: DomSanitizer) { }
  @Input() data;
  safeUrl: SafeResourceUrl;
  ngOnInit(): void {
    if (this.data?.type === 'PDF' && this.data?.url) {
      // Sanitize the URL if it's a PDF
      this.safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
    }
  }

  close(){
    this.ref.close();
  }

}
