<nb-card class="card-box">
    <nb-card-header>
        <h3>{{type}} Excel Import</h3>
    </nb-card-header>
    <nb-card-body>

        <ng2-smart-table [settings]="settings" [source]="source">
        </ng2-smart-table>


    </nb-card-body>
    <nb-card-footer>
        <div class="float-right">
            <button nbButton class="mr-2" status="danger" hero (click)="close()">Discard Import</button>
            <button nbButton class="mr-2" status="primary" hero (click)="import()">Confirm Import</button>

        </div>

    </nb-card-footer>
</nb-card>