<nb-card class="card-box">

    <nb-card-header class="card-header">
        <h6>File Viewer</h6>
        <button nbButton status="danger" (click)="close()">Close</button>
    </nb-card-header>
    <nb-card-body class="card-body">
        <img *ngIf="data.type=='IMAGE'" [src]="data.url" alt="image">
        <iframe *ngIf="data.type === 'PDF'" [src]="safeUrl" width="100%" height="500px" frameborder="0"></iframe>


    </nb-card-body>



</nb-card>