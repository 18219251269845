<nb-card class="card-box">
    <nb-card-header>
        <h3>File Upload</h3>

        <div class="type">{{description}}</div>
    </nb-card-header>
    <nb-card-body>

        <nb-tabset (changeTab)="onTabChange($event)">
            <nb-tab tabIcon="cloud-upload-outline" tabTitle="Upload">
                <div>
                    <div class="error-txt" *ngIf="validationError">
                        <nb-icon icon="alert-triangle-outline"></nb-icon> {{validationError}}
                    </div>
                    <nb-progress-bar *ngIf="imagePicked" [value]="progress" [displayValue]="true" [status]="status"></nb-progress-bar>

                    <div class="uploaded-box" style="position: relative;">
                        <div *ngIf="!isFilePicked">
                            <label class="label choose-file" for="file">{{allowedType==='IMAGE'?'Choose Image':'Choose File'}}</label>
                            <input type="file" class="d-none" id="file" nbInput (change)="onSelectFile($event)">
                        </div>


                        <img [src]="pickedImage" height="200">
                     
                        <div class="file-block"  *ngIf="selectedFileType=='pdf'">
                            <div class="file-icon" >
                                <nb-icon icon="file-text-outline" style="font-size: 100px;"></nb-icon>
                            </div>
                            <div class="filename">{{pickedFileName}}</div>
                           
                        </div>

                    </div>

                    <div class="change-btn" *ngIf="selectedFile">
                        <button class="btn-change" (click)="changePickedImage()">Change Selection </button>
                    </div>
                </div>
            </nb-tab>
            <nb-tab tabTitle="Pick From Gallery" tabIcon="image-outline">
                <div class="filebox">
                    <div class="fileitem" *ngFor="let file of allFiles;index as i;">
                        <img [src]="file?.url" alt="image" width="100px" height="100px" [ngClass]="{'picked-gallery-image':pickedGallaryIndex==i}" (click)="selectFromGallary(file,i)">
                    </div>
                </div>
            </nb-tab>
        </nb-tabset>


    </nb-card-body>
    <nb-card-footer>

        <div class="float-right">
            <button nbButton class="mr-3" status="danger" hero (click)="cancel()">Cancel</button>
            <button nbButton status="success" hero [disabled]="!selectedFile" (click)="selectAndClose()">Select</button>
        </div>
    </nb-card-footer>
</nb-card>