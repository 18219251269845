import { Component, OnInit,Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { ApiServiceService } from '../api-service.service';
@Component({
  selector: 'ngx-fileupload',
  templateUrl: './fileupload.component.html',
  styleUrls: ['./fileupload.component.scss']
})
export class FileuploadComponent implements OnInit {

  constructor(private dialog:NbDialogRef<FileuploadComponent>,private api:ApiServiceService) { }
  @Input() data;

  validationError:any;
  pickedFileName:any;
  pickedImage:any;
  allFiles:any;
  progress = 0;
  status = 'success';
  imagePicked:boolean= false;
  selectedFile:any;
  pickedGallaryIndex:any;
  description:any;
  allowedType:any;
  label:any;
  dimentionCheck:boolean = false;
  pickedFile:any;
  isInvalid:boolean = false;
  selectedFileType:any;
  isFilePicked:boolean = false;
  ngOnInit(): void {
    this.getFiles();
    this.description=this.data.description;
    this.allowedType=this.data?.allowedType??'IMAGE';
    if(this.data.label){
      this.label=this.data.label;
    }
    else{
      this.label='No Label';
    }

    if(this.data.width && this.data.height){
      this.dimentionCheck = true;
      console.log('check Point',this.dimentionCheck);
      
    }

  }



  getFiles(){
    this.api.getUserFiles().subscribe((res:any)=>{
      console.log(res);
      this.allFiles=res['data'];
    },
    (err)=>{
      console.log(err);
    }
    );

  }


  onSelectFile(event) {
    this.validationError = '';
    this.pickedFileName = '';
    this.pickedImage = '';
    this.isInvalid = false;
    if (event.target.files && event.target.files[0]) {
      this.pickedFileName = event.target.files[0].name;
      
       
         if(this.allowedType == 'IMAGE' && event.target.files[0].type != 'image/jpeg' && event.target.files[0].type != 'image/png'){
          this.api.toastrError('File type should be jpg or png','Error');
          this.validationError = 'File type should be jpg or png';
          this.isInvalid = true;
          
          return;
        }
        
          // let sizeCheck=this.checkFileSize(1000000,event.target.files[0].size);
          // if(!sizeCheck)
          //   {
          // this.api.toastrError('File size should be less than 1 MB','Error');
          // this.validationError = 'File size should be less than 1 MB';
          // this.isInvalid = true;
          // return;
          // }
        
          //take image dimension
          if(event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/png' && this.dimentionCheck){
            console.log('check Point image block entered');
            this.selectedFileType = 'image';
          const img = new Image();
          img.src = window.URL.createObjectURL(event.target.files[0]);
          img.onload = () => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            window.URL.revokeObjectURL(img.src);
            console.log('check Point img dimention',width,height);
            
              let dimensionCheck = this.checkFileDimension(width,height,this.data.width,this.data.height);
              if(!dimensionCheck){
                this.api.toastrError('Image dimension should be '+this.data.width+'x'+this.data.height,'Error');
                this.validationError = 'Image dimension should be '+this.data.width+'x'+this.data.height;
                this.isInvalid = true;
                return;
              }
              if (!this.isInvalid) {                    
                console.log('invalid Point',this.isInvalid);                  
                console.log(event.target.files[0]);          
                const reader = new FileReader();          
                reader.readAsDataURL(event.target.files[0]);          
                reader.onload = (event: any) => {             
                    this.pickedImage = event.target.result;             
                    this.imagePicked = true; 
                    this.isFilePicked = true;                       
                }          
                this.upload(event.target.files[0]);         
            }      
            
          }
        }
        else if( event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/png' && this.isInvalid === false){
         
          console.log('invalid Point',this.isInvalid);
          this.selectedFileType = 'image';
          console.log(event.target.files[0]);
          const reader = new FileReader();
          reader.readAsDataURL(event.target.files[0]);
          reader.onload = (event: any) => {
            this.pickedImage = event.target.result;
            this.imagePicked = true;
            this.isFilePicked = true;
            
          }
          this.upload(event.target.files[0]);
        }
        else if (event.target.files[0].type === 'application/pdf' && this.isInvalid === false) {
          this.selectedFileType = 'pdf';
          console.log('PDF file selected:', event.target.files[0].name);
          this.pickedFileName = event.target.files[0].name;
          this.pickedImage = null; // Clear any previous image data
          this.imagePicked = false; // PDFs are not considered images
          this.isFilePicked = true;
          this.upload(event.target.files[0]); // Directly upload the PDF file
        } 
        else {
          console.log('Unsupported file type:', event.target.files[0].type);
          // Handle invalid file types (optional)
          this.pickedImage = null;
          this.imagePicked = false;
        }



      }
  }



  upload(data){
    this.progress = 30;
    const formdata = new FormData();
    formdata.append('image', data);
    formdata.append('label', this.label);
        this.api.fileUploadS3(formdata).subscribe((res:any)=>{
          console.log(res);
          this.api.toastrSuccess('File Uploaded Successfully','success')
          this.progress = 60;
          this.progress = 100;
          this.selectedFile = res['data'];
          // this.dialog.close(res);
        },
        (err)=>{
          console.log(err);
        }

        )
      
  }

  cancel(){
    this.dialog.close({'status':false});
  }

  selectAndClose(){
    this.dialog.close({'status':true,'data':this.selectedFile});
  }


  changePickedImage(){
    this.pickedImage = '';
    this.pickedFileName = '';
    this.imagePicked = false;
    this.isFilePicked = false;
    this.selectedFile=null;
  }

  selectFromGallary(item:any,index){
    this.selectedFile = item;
    this.pickedGallaryIndex = index;
  }


  onTabChange(event){
    console.log(event);
    if(event.tabTitle == 'Upload'){
      this.pickedImage = '';
      this.pickedFileName = '';
      this.imagePicked = false;
      this.isFilePicked = false;
     this.selectedFile = null;
     this.validationError = '';
     this.selectedFileType = null;

    }
    else{
      this.selectedFile = null;
      this.pickedGallaryIndex = null;
      this.validationError = '';

    }
  }


  checkFileSize(maxsize,size){
    if(size > maxsize){
      return false;
    }
    else{
      return true;
    }
  }

  checkFileDimension(width,height,maxwidth,maxheight){
    if(width > maxwidth || height > maxheight){
      return false;
    }
    else{
      return true;
    }
  }

}
